import React, { useState } from "react";

const searchIcon = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.9765 11.4713H12.1865L11.9065 11.2013C13.1065 9.80133 13.7265 7.89133 13.3865 5.86133C12.9165 3.08133 10.5965 0.861329 7.79646 0.521329C3.56646 0.00132874 0.00645569 3.56133 0.526456 7.79133C0.866456 10.5913 3.08646 12.9113 5.86646 13.3813C7.89646 13.7213 9.80646 13.1013 11.2065 11.9013L11.4765 12.1813V12.9713L15.7265 17.2213C16.1365 17.6313 16.8065 17.6313 17.2165 17.2213C17.6265 16.8113 17.6265 16.1413 17.2165 15.7313L12.9765 11.4713ZM6.97646 11.4713C4.48646 11.4713 2.47646 9.46133 2.47646 6.97133C2.47646 4.48133 4.48646 2.47133 6.97646 2.47133C9.46646 2.47133 11.4765 4.48133 11.4765 6.97133C11.4765 9.46133 9.46646 11.4713 6.97646 11.4713Z"
      fill="#ADB5BD"
    />
  </svg>
);

const closeIcon = (
  <svg
    width="15"
    height="14"
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.49992 0.333008C3.81325 0.333008 0.833252 3.31301 0.833252 6.99967C0.833252 10.6863 3.81325 13.6663 7.49992 13.6663C11.1866 13.6663 14.1666 10.6863 14.1666 6.99967C14.1666 3.31301 11.1866 0.333008 7.49992 0.333008ZM10.3666 9.86634C10.1066 10.1263 9.68659 10.1263 9.42659 9.86634L7.49992 7.93967L5.57325 9.86634C5.31325 10.1263 4.89325 10.1263 4.63325 9.86634C4.37325 9.60634 4.37325 9.18634 4.63325 8.92634L6.55992 6.99967L4.63325 5.07301C4.37325 4.81301 4.37325 4.39301 4.63325 4.13301C4.89325 3.87301 5.31325 3.87301 5.57325 4.13301L7.49992 6.05967L9.42659 4.13301C9.68659 3.87301 10.1066 3.87301 10.3666 4.13301C10.6266 4.39301 10.6266 4.81301 10.3666 5.07301L8.43992 6.99967L10.3666 8.92634C10.6199 9.17968 10.6199 9.60634 10.3666 9.86634Z"
      fill="#ADB5BD"
    />
  </svg>
);

const SimpleSearchBar = ({ path }) => {
  const urlParams = new URLSearchParams(window.location.search);

  const [query, setQuery] = useState(urlParams.get("q") ?? "");
  const [activeSearch, setActiveSearch] = useState(query !== "");

  const handleSearch = (event) => {
    if (event.key === "Enter") {
      window.location.href = `${path}?q=${query}`;
    }
  };

  const handleBlur = () => {
    if (query == "") {
      setActiveSearch(false);
    }
  };

  const restartSearch = () => {
    setActiveSearch(false);
    setQuery("");
  };

  return (
    <>
      {!activeSearch && (
        <button
          className="main-searchbar__button"
          type="button"
          onClick={() => setActiveSearch(true)}
        >
          {searchIcon}
          <span>BUSCAR</span>
        </button>
      )}

      {activeSearch && (
        <div className="main-searchbar__input-wrapper">
          {searchIcon}
          <input
            type="text"
            value={query}
            onChange={({ target: { value } }) => setQuery(value)}
            placeholder="Buscar"
            onKeyDown={handleSearch}
            autoFocus
            onBlur={handleBlur}
          />

          <button
            className="main-searchbar__close"
            type="button"
            onClick={restartSearch}
          >
            {closeIcon}
          </button>
        </div>
      )}
    </>
  );
};

export default SimpleSearchBar;
