import React, { useRef } from 'react';
import PropTypes from 'prop-types';

const arrowRight = (
  <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.66094 10L0.648438 2.98754L2.6516 0.984375L11.6673 10L2.6516 19.0157L0.648438 17.0125L7.66094 10Z" fill="#FD495C" />
  </svg>
);

const SubcategoryList = ({ subcategories }) => {
  const list = useRef();

  const handleScroll = () => {
    const { scrollLeft, scrollWidth, offsetWidth } = list.current;

    list.current.scrollTo({
      top: 0,
      left: offsetWidth === scrollWidth - scrollLeft ? 0 : scrollLeft + 300,
      behavior: 'smooth',
    });
  };

  return (
    <>
      <ul className="subcategory-list" ref={list}>
        {subcategories.map((sub) => (
          <li key={sub.name}>
            {/* {sub.iconUrl && <img src={sub.iconUrl} alt='' />} */}
            <a href={sub.url}>
              {sub.name}
            </a>
          </li>
        ))}
      </ul>

      {/* <button type="button" onClick={handleScroll}>{arrowRight}</button> */}
    </>
  );
};

SubcategoryList.propTypes = {
  subcategories: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default SubcategoryList;
