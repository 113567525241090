import VideoPlayer from "../VideoPlayer";
import React from "react";

const streamIcon = (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="31" y="31" width="30" height="30" rx="15" transform="rotate(-180 31 31)" fill="#FCFCFC"/>
    <path d="M12 9.14062V23.1406L23 16.1406L12 9.14062Z" fill="#E31519"/>
    <rect x="31" y="31" width="30" height="30" rx="15" transform="rotate(-180 31 31)" stroke="#E31519" strokeWidth="2"/>
  </svg>
);

const videoJsOptions = {
  autoplay: true,
  controls: true,
};

const StreamingHeader = ({ title, liveUrl, src }) => (
  <div className="ott-header">
    <div className="ott-header__container">
      <div className="ott-header__streaming">
        <a href={liveUrl} className="ott-header__streaming__status">
          <div className="ott-header__streaming__status__container">
            <p className="ott-header__streaming__status__text">
                EN VIVO
            </p>
            <div className="ott-header__streaming__status__icon">
              {streamIcon}
            </div>
          </div>
        </a>
        <VideoPlayer live {...videoJsOptions} uid={src} />
        <h1 className="ott-header__streaming__title">
          {title}
        </h1>
      </div>
    </div>
  </div>
);

export default StreamingHeader;
