import React from "react";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

const VideoHeader = ({ videos }) => (
  <>
    <Swiper
      pagination={true}
      navigation={true}
      modules={[Navigation, Pagination]}
      className="video-swiper"
    >
      {videos.map(({ title, cta, background, background_mobile, logo }) => (
        <SwiperSlide key={cta}>
          <div className="card">
            <a href={cta} className="card__image">
              <img className="d-xl-none" src={background_mobile} />
              <img className="d-none d-xl-block" src={background} />
            </a>
            <div className="card__content">
              <img className="card__content__logo" src={logo} />
              <h3 className="card__content__title">{title}</h3>
              {/* <span className="card__content__subtitle">{subtitle}</span> */}
              {/* <p className="card__content__description">{description}</p> */}
              {/* <a href={cta} className="card__content__cta">
                VER
              </a> */}
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  </>
);

VideoHeader.propTypes = {};

export default VideoHeader;
