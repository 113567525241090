import React from 'react';
// Import Swiper React components
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

export default () => {
  return (
    <section className="galery">
      <h2 className="galery__title">Galería</h2>
      <Swiper
        navigation={true}
        modules={[Navigation]}
        className="mySwiper"
      >
        <SwiperSlide className="galery__image">
          <p>Slide 1</p>  
        </SwiperSlide>
        <SwiperSlide className="galery__image">
          <p>Slide 2</p>  
        </SwiperSlide>
        <SwiperSlide className="galery__image">
          <p>Slide 3</p>  
        </SwiperSlide>
        <SwiperSlide className="galery__image">
          <p>Slide 4</p>  
        </SwiperSlide>
        <SwiperSlide className="galery__image">
          <p>Slide 5</p>  
        </SwiperSlide>
      </Swiper>
      <Swiper
        slidesPerView={2}
        spaceBetween={2}
        navigation={true}
        modules={[Navigation]}
        className="galery__secondary"
      >
        <SwiperSlide>
          <div className="galery__image-secondary">Slide 1</div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="galery__image-secondary">Slide 2</div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="galery__image-secondary">Slide 3</div>
        </SwiperSlide>
      </Swiper>
      <Swiper
        slidesPerView={2}
        spaceBetween={2}
        navigation={true}
        modules={[Navigation]}
        className="galery__secondary"
      >
        <SwiperSlide>
          <div className="galery__image-secondary">Slide 1</div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="galery__image-secondary">Slide 2</div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="galery__image-secondary">Slide 3</div>
        </SwiperSlide>
      </Swiper>
      <div className="galery__button-area">
        <a className="btn-primary" href="#">Leer número</a>
      </div>
    </section>
  );
};