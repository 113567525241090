import { Stream } from "@cloudflare/stream-react";
import React from "react";
import VideoJs from "./ott/streaming/VideoPlayer";

const videoUrl = (uid) =>
  uid
    ? `https://customer-rzo7kaewpi03y0c6.cloudflarestream.com/${uid}/manifest/video.m3u8`
    : "";

const VideoPlayer = ({ uid, live, videoProps = {} }) =>
  live ? (
    <VideoJs controls sources={[{ src: videoUrl(uid) }]} {...videoProps} />
  ) : (
    <Stream src={uid} controls {...videoProps} />
  );

export default VideoPlayer;
