import React from 'react'
import videojs from '!video.js'
// import 'video.js/dist/video-js.css'

export default class VideoPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorCode: null,
      maxRetries: 10,
    };
  }

  componentDidMount() {
    const sources = this.props.sources;
    this.player = videojs(this.videoNode, this.props);
    this.player.reloadSourceOnError({
      getSource: (reload) => setTimeout(() => reload(sources[0]), 2000),
      errorInterval: 5,
    });
    this.player.on("error", () => {
      const error = this.player.error();
      const retriesLeft = this.state.maxRetries;

      if (error.code === 4 && retriesLeft > 0) {
        this.setState((prevState) => ({
          errorCode: error.code,
          maxRetries: prevState.maxRetries - 1,
        }));

        this.player.reloadSourceOnError({
          getSource: (reload) => setTimeout(() => reload(sources[0]), 2000),
          errorInterval: Math.floor(Math.random() * 10),
        });
      }
    });
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  render() {
    const showErrorScreen = this.state.errorCode == 4;

    return (
      <>
        {showErrorScreen && <div className="vid-error-screen" />}
        <div data-vjs-player style={{ width: "100%", height: "100%" }}>
          <video
            ref={(node) => {
              this.videoNode = node;
            }}
            className="video-js"
          />
        </div>
      </>
    );
  }
}
