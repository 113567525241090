import React from "react";

const LinkIf = ({ condition, url, children, className }) => {
  return condition ? (
    <a href={url} target="_blank" className={className}>
      {children}
    </a>
  ) : (
    <div className={className}>
      {children}
    </div>
  );
};

export default LinkIf;
