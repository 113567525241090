import React, { useState } from "react";
import SimpleSearchBar from "../../HomePage/components/SimpleSearchBar";

const Header = ({ icons, links }) => {
  const { logo, close, menu, house, program, liveIcon } = icons;
  const { home, pageInfo, programs, live } = links;
  const [overlay, setOverlay] = useState(false);

  return (
    <>
      <div className="navbar-mobile">
        <a href={home} className="navbar__logo">
          <span dangerouslySetInnerHTML={{ __html: logo }} />
        </a>

        <div className="navbar__tools">
          <SimpleSearchBar path="/search/main" />
          <button
            className="navbar__dropdown"
            onClick={() => setOverlay(!overlay)}
            dangerouslySetInnerHTML={{ __html: overlay ? close : menu }}
          />
        </div>
      </div>

      {overlay && (
        <div className="overlay">
          <div className="main-navigation">
            <a href={home} className="main-navigation__item">
              <span dangerouslySetInnerHTML={{ __html: house }} />
              INICIO
            </a>
            <a href={programs} className="main-navigation__item">
              <span dangerouslySetInnerHTML={{ __html: program }} />
              PROGRAMACIÓN
            </a>
            <a href={live} className="main-navigation__item">
              <span dangerouslySetInnerHTML={{ __html: liveIcon }} />
              EN VIVO
            </a>
          </div>
          <div className="navbar__footer footer">
            <span className="grada-navigation__item" dangerouslySetInnerHTML={{ __html: logo }} />
            {pageInfo.map(({ name, url }) => (
              <a href={url} key={name} className="grada-navigation__item">
                {name}
              </a>
            ))}
            <p className="footer__copyright">
              © TVIN 2023. Todos los derechos reservados.
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
