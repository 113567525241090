const reducer = (state, action) => {
  switch (action.type) {
    case "fetchSuccess": {
      return { ...state, chatroom: action.payload.data, errors: [] };
    }
    case "fetchFailure": {
      return { ...state, errors: action.payload.data };
    }
    case "emojiNew": {
      const updatedReaction = { ...state.chatroom };
      updatedReaction.emojis = { ...state.chatroom.emojis, ...action.payload };
      return { ...state, chatroom: updatedReaction };
    }
    case "chatConnection": {
      return { ...state, chatConnection: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
