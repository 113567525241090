import React from "react";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import FacebookLogin from "@greatsumini/react-facebook-login";
import { APPLE_CLIENT_ID, GOOGLE_CLIENT_ID, FACEBOOK_APP_ID } from "./oauthCreds";
import AppleSignin from 'react-apple-signin-auth';

const fbLogo = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="100"
    height="100"
    viewBox="0 0 48 48"
  >
    <path fill="#039be5" d="M24 5A19 19 0 1 0 24 43A19 19 0 1 0 24 5Z"></path>
    <path
      fill="#fff"
      d="M26.572,29.036h4.917l0.772-4.995h-5.69v-2.73c0-2.075,0.678-3.915,2.619-3.915h3.119v-4.359c-0.548-0.074-1.707-0.236-3.897-0.236c-4.573,0-7.254,2.415-7.254,7.917v3.323h-4.701v4.995h4.701v13.729C22.089,42.905,23.032,43,24,43c0.875,0,1.729-0.08,2.572-0.194V29.036z"
    ></path>
  </svg>
);

const appleLogo = (
  <svg
    fill="#ffffff"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="64px"
    height="64px"
    viewBox="-27 -27 565 565"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      <g id="7935ec95c421cee6d86eb22ecd114eed">
        <path
          style={{ display: "inline" }}
          d="M248.644,123.476c-5.45-29.71,8.598-60.285,25.516-80.89 c18.645-22.735,50.642-40.17,77.986-42.086c4.619,31.149-8.093,61.498-24.826,82.965 C309.37,106.527,278.508,124.411,248.644,123.476z M409.034,231.131c8.461-23.606,25.223-44.845,51.227-59.175 c-26.278-32.792-63.173-51.83-97.99-51.83c-46.065,0-65.542,21.947-97.538,21.947c-32.96,0-57.965-21.947-97.866-21.947 c-39.127,0-80.776,23.848-107.19,64.577c-9.712,15.055-16.291,33.758-19.879,54.59c-9.956,58.439,4.916,134.557,49.279,202.144 c21.57,32.796,50.321,69.737,87.881,70.059c33.459,0.327,42.951-21.392,88.246-21.616c45.362-0.258,53.959,21.841,87.372,21.522 c37.571-0.317,67.906-41.199,89.476-73.991c15.359-23.532,21.167-35.418,33.11-62.023 C414.435,352.487,389.459,285.571,409.034,231.131z"
        ></path>
      </g>
    </g>
  </svg>
);

const OauthLogin = () => {
  const fetchServerResponse = (data) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    return fetch(`api/v1/social_auth/callback`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        Object.keys(response.headers).forEach((k) => {
          document.cookie = `${k}=${response.headers[k]}; path=/`;
        });

        window.location.reload();
      })
      .catch((err) => console.log(err));
  };

  const responseGoogle = (response) => {
    const { clientId, credential } = response;

    const data = {
      provider: "google_oauth2",
      client_id: clientId,
      id_token: credential,
    };

    return fetchServerResponse(data);
  };

  const responseFacebook = (response) => {
    const { accessToken } = response;

    const data = {
      provider: "facebook",
      id_token: accessToken,
    };

    return fetchServerResponse(data);
  };

  const responseApple = (response) => {
    const { authorization: { id_token }, user } = response;

    const { email, name } = user || {};
    const { firstName, lastName} = name || {}

    const data = {
      provider: "apple",
      id_token: id_token,
      user: {
        email: email,
        name: `${firstName} ${lastName}`
      }
    };

    return fetchServerResponse(data);
  };

  return (
    <>
      <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
        <GoogleLogin
          height="10"
          width="280px"
          backgroundColor="#4285f4"
          access="offline"
          scope="email profile"
          locale="es"
          onSuccess={responseGoogle}
          onFailure={responseGoogle}
        />
      </GoogleOAuthProvider>
      <FacebookLogin
        appId={FACEBOOK_APP_ID}
        onSuccess={responseFacebook}
        onFail={(error) => console.log(error)}
        render={({ onClick }) => (
          <button
            className="login-btn facebook-btn"
            type="button"
            onClick={onClick}
          >
            {fbLogo}
            Iniciar sesión con Facebook
          </button>
        )}
      />
      <AppleSignin 
        authOptions={{
          clientId: APPLE_CLIENT_ID,
          scope: 'email name',
          redirectURI: 'https://tvin.mx/api/v1/social_auth/callback',
          state: 'state',
          usePopup: true
        }}
        onSuccess={responseApple}
        render={({ onClick }) => (
          <button
            className="login-btn apple-btn"
            type="button"
            onClick={onClick}
          >
            {appleLogo}
            Iniciar sesión con Apple
          </button>
        )}
      />
    </>
  );
};

export default OauthLogin;
