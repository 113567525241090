import React, {  useState } from "react";
import { useWindowSize } from "../../../hooks/useWindowSize";

const WrapIf = ({ condition, onCollapse, children }) => {
  const [dragDistance, setDragDistance] = useState(0);

  const size = useWindowSize();

  // Get height using aspect ratio
  const videoHeight = (size[0] * 6) / 10;

  // Navbar height + Live banner height
  const layoutHeight = 64 + 28;

  const topPosition = videoHeight + layoutHeight + 19;

  const expandOrCollapse = () => {
    const threshold = 130;

    if (dragDistance > threshold) {
      onCollapse();
      setDragDistance(0);
    } else if (dragDistance < threshold * -1) {
      setDragDistance((topPosition * -1) + 16);
    } else {
      setDragDistance(0);
    }
  };

  const handleDrag = (e) => {
    setDragDistance(e.clientY - topPosition);
  };

  const handleDragStart = (e) => {
    e.dataTransfer.effectAllowed = "move";

    e.dataTransfer.setDragImage(
      e.target,
      window.outerWidth,
      window.outerHeight
    );
  };

  return condition ? (
    <div
      style={{ top: topPosition + dragDistance - 16 }}
      className="fullscreen-wrapper"
      onDragOver={(e) => e.preventDefault()}
    >
      <div
        onDragStart={(e) => handleDragStart(e)}
        onDrag={(e) => handleDrag(e)}
        onDragEnd={expandOrCollapse}
        className="draggable-item"
        draggable
      />
      <p className="live-comments__title">Chat en línea</p>
      {children}
    </div>
  ) : (
    children
  );
};

export default WrapIf;
