import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

const ShowMore = ({ content, tags }) => {
  const [show, setShow] = useState(false);
  const [displayBtn, setDisplayBtn] = useState(true);
  const descriptionRef = useRef();

  useEffect(() => {
    const lineHeight = 20;

    if (descriptionRef.current.clientHeight <= lineHeight * 3) {
      console.log("we need some backup");
      setDisplayBtn(false);
    }
  }, []);

  const divClass = displayBtn ? show ? "full-content" : "half-content" : '';

  return (
    <>
      <div className={`content ${divClass}`}>
        <div
          ref={descriptionRef}
          dangerouslySetInnerHTML={{ __html: content }}
        />
        <div className="tag-container">
          {tags.map(({ name, url, image }) => (
            <a href={url} className="item">
              <img src={image} />
              <span>{name}</span>
            </a>
          ))}
        </div>
      </div>

      {displayBtn && (
        <button onClick={() => setShow(!show)}>
          <span>{show ? "Ver menos" : "Ver más"}</span>
        </button>
      )}
    </>
  );
};

ShowMore.propTypes = {
  initial: PropTypes.bool,
  content: PropTypes.string.isRequired,
  tags: PropTypes.array,
};

ShowMore.defaultProps = {
  initial: false,
};

export default ShowMore;
