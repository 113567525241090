import React, { useEffect, useReducer, useState } from "react";
import consumer from "../../../../channels/consumer";
import OauthLogin from "./OauthLogin";
import LinkIf from "./LinkIf";
import WrapIf from "./WrapIf";
import reducer from "./chatReducer";
import EmojiReactions from "./EmojiReactions";

const sendIcon = (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.67124 17.337L19.1212 9.85703C19.9312 9.50703 19.9312 8.36703 19.1212 8.01703L1.67124 0.537029C1.01124 0.247029 0.28124 0.737029 0.28124 1.44703L0.27124 6.05703C0.27124 6.55703 0.64124 6.98703 1.14124 7.04703L15.2712 8.93703L1.14124 10.817C0.64124 10.887 0.27124 11.317 0.27124 11.817L0.28124 16.427C0.28124 17.137 1.01124 17.627 1.67124 17.337Z"
      fill="#CF2032"
    />
  </svg>
);

const closeIcon = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
      fill="#FCFCFC"
    />
  </svg>
);

const exit = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.40316 3.33333H7.40316C7.76982 3.33333 8.06982 3.03333 8.06982 2.66667C8.06982 2.3 7.76982 2 7.40316 2H3.40316C2.66982 2 2.06982 2.6 2.06982 3.33333V12.6667C2.06982 13.4 2.66982 14 3.40316 14H7.40316C7.76982 14 8.06982 13.7 8.06982 13.3333C8.06982 12.9667 7.76982 12.6667 7.40316 12.6667H3.40316V3.33333Z"
      fill="#CF2032"
    />
    <path
      d="M13.8365 7.76667L11.9765 5.90667C11.7632 5.69333 11.4032 5.84 11.4032 6.14V7.33333H6.73649C6.36982 7.33333 6.06982 7.63333 6.06982 8C6.06982 8.36667 6.36982 8.66667 6.73649 8.66667H11.4032V9.86C11.4032 10.16 11.7632 10.3067 11.9698 10.0933L13.8298 8.23333C13.9632 8.10667 13.9632 7.89333 13.8365 7.76667Z"
      fill="#CF2032"
    />
  </svg>
);

const getCookies = () => {
  const cookies = {};

  const rawCookies = document.cookie.split(";").map((i) => i.split("="));

  rawCookies.map((c) => (cookies[c[0].trim()] = c[1]));

  return cookies;
};

const LiveChat = ({ liveChat, commenterSignup, user }) => {
  const [state, dispatch] = useReducer(reducer, {
    chatroom: { name: liveChat.name, id: liveChat.id, messages: [] },
    errors: [],
    chatConnection: {},
  });

  const [message, setMessage] = useState("");
  const [showChatModal, setShowChatModal] = useState(false);

  const { chatroom, chatConnection } = state;

  useEffect(() => {
    const createSocket = () => {
      const subscription = consumer.subscriptions.create(
        {
          channel: "LiveChatChannel",
          room: chatroom.name,
        },
        {
          connected: () => {
            fetch(`/api/v1/chatroom/${chatroom.id}`).then((r) => {
              if (r.ok) {
                r.json().then((data) => {
                  dispatch({ type: "fetchSuccess", payload: data });
                });
              } else {
                r.json().then((e) => {
                  dispatch({ type: "fetchFailure", payload: e.errors });
                });
              }
            });
          },
          received: (data) => {
            const { action, message } = data;
            dispatch({ type: action, payload: message });
          },
        }
      );
      dispatch({ type: "chatConnection", payload: subscription });
    };

    if (chatroom.name) {
      createSocket();
    }
  }, [chatroom.name, chatroom.id]);

  useEffect(() => {
    const footer = document.querySelector("footer");

    if (showChatModal) footer.classList.add("d-none");
    if (!showChatModal) footer.classList.remove("d-none");
  }, [showChatModal]);

  const handleSend = () => {
    if (message.trim() == "") return;

    const { uid, client, "access-token": token } = getCookies();

    chatConnection.send({
      message: {
        content: message,
        chatroom_id: chatroom.id,
      },
      commenter: { uid, client, token },
    });

    setMessage("");
  };

  const sendOnEnter = (e) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      handleSend();
    }
  };

  const handleLogout = () => {
    document.cookie = "uid=0; path=/"
    document.location.reload();
  };

  const hideModal = () => setShowChatModal(false);

  const chatEmpty = chatroom.messages.length == 0;

  return (
    <div className={`live-chat ${showChatModal ? "" : "position-relative"}`}>
      <WrapIf condition={showChatModal} onCollapse={hideModal}>
        <button className="close-btn" onClick={hideModal}>
          {closeIcon}
        </button>
        {commenterSignup && !showChatModal && (
          <div className="live-chat__session">
            <p>
              Identificado como
              {" "}
              <img src={user.picture} />
              {" "}
              <span>{user.name}</span>
            </p>
            <button onClick={handleLogout}>Salir {exit}</button>
          </div>
        )}
        <div className={`live-chat__comments ${chatEmpty ? "--empty" : ""}`}>
          {!chatEmpty &&
            chatroom.messages.map((m) => (
              <LinkIf
                url={m.url}
                condition={m.url}
                className={`live-chat__comments__item ${
                  m.featured ? "--featured" : ""
                }`}
              >
                <img src={m.avatar} referrerPolicy="no-referrer" />
                <div>
                  <span>
                    <strong>{m.name}</strong>
                    {m.time}
                  </span>
                  <p>{m.content}</p>
                </div>
              </LinkIf>
            ))}

          {chatEmpty && (
            <>
              <p>Este video no tiene comentarios</p>
              <p>¡Sé el primero en comentar!</p>
            </>
          )}
        </div>

        <EmojiReactions liveChat={liveChat} />

        {!commenterSignup && (
          <div className="login">
            <div className="login__content">
              <p>Inicia sesión para empezar a enviar mensajes</p>
              <OauthLogin className="chat-oauth" />
            </div>
          </div>
        )}

        {commenterSignup && (
          <>
            <div className="live-chat__input">
              <textarea
                rows={1}
                placeholder="Escribir un mensaje..."
                value={message}
                onChange={({ target }) => setMessage(target.value)}
                onKeyDown={sendOnEnter}
              />
              <button
                disabled={message.trim() === ""}
                type="button"
                onClick={handleSend}
                title="Enviar"
              >
                {sendIcon}
              </button>
            </div>

            <button
              className="live-chat__toggle"
              onClick={() => setShowChatModal(true)}
            >
              Escribir un mensaje...
              {sendIcon}
            </button>
          </>
        )}
      </WrapIf>
    </div>
  );
};

export default LiveChat;
